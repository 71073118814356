export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBD8BSf5fxIZggtZkTRgFCEkK7Vb86ytWY',
    authDomain: 'nnkphbs.firebaseapp.com',
    databaseURL: 'https://nnkphbs-default-rtdb.firebaseio.com',
    projectId: 'nnkphbs',
    storageBucket: 'nnkphbs.appspot.com',
    messagingSenderId: '879988682782',
    appId: '1:879988682782:web:039d8441ed8aa7b1747e0f',
    measurementId: 'G-Y17NNV2L05'
  }
}
